<template>
  <div class="row">
    <DangerAlert v-if="errors.length" v-for="(message) in errors" :message="message"/>
    <OrderPayment v-if="order" :order="order"/>
    <div class="col-6" v-if="!order">
      <div class="input-group mb-3">
        <span class="input-group-text">Name & Surname *:</span>
        <input v-model="name" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Company:</span>
        <input v-model="company" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Telephone *:</span>
        <input v-model="contact" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Email *:</span>
        <input v-model="email" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text">Payment Method:</label>
        </div>
        <div class="form-control no-line-break">
          <label class="mouse-pointer">
            <input id="card" checked="checked" v-model="paymentMethod" type="radio" value="CARD">
            Credit Card &nbsp;
          </label>
          <label class="mouse-pointer ml-3">
            <input id="eft" v-model="paymentMethod" type="radio" value="EFT">
            EFT
          </label>
        </div>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text">Delivery/Collection:</label>
        </div>
        <div class="form-control no-line-break">
          <label class="mouse-pointer">
            <input id="delivery" checked="checked" v-model="deliveryMethod" type="radio" value="D">
            Delivery &nbsp;
          </label>
          <label class="mouse-pointer ml-3">
            <input id="collection" v-model="deliveryMethod" type="radio" value="C">
            Collection
          </label>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="!order">
      <div class="alert alert-success" role="alert">
        <h4 class="alert-heading">Order Summary</h4>
        <p>
          EFT Payments Banking Details and Order Reference will be emailed to you after check out.<br><br>
          We offer a 3.5% discount on EFT payments
        </p>
        <hr>
        <p>
          <b>Current Discount: R{{ this.discountApplied }}</b><br>
          <b>Current Delivery Fee: R{{ this.deliveryCost }}</b>
        </p>
        <p class="mb-0">
          Total (Items: {{ cartCount() }}):
          <b> R
            <span class="summary_total">
          {{ cartTotal() }}
        </span>
          </b>
        </p>
      </div>
    </div>
  </div>
  <div class="row" v-if="!order">
    <h4 class="page_titles">
      <i class="fa fa-minus"></i>
      Address Info
      <i class="fa fa-minus"></i>
    </h4>
  </div>
  <div class="row" v-if="!order">
    <div class="col-md-12 col-xl-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text">Same Billing Address:</label>
        </div>
        <div class="form-control no-line-break">
          <label class="mouse-pointer">
            <input id="separate" checked="checked" v-model="sameDelivery" type="radio" value="separate">
            Separate &nbsp;
          </label>
          <label class="mouse-pointer ml-3">
            <input id="same" v-model="sameDelivery" type="radio" value="same">
            Same
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-xl-6">
    </div>
    <div class="col-md-12 col-xl-6">
      <div class="input-group mb-3">
        <span class="input-group-text">Billing Address Line 1*:</span>
        <input v-model="billingAddressLine1" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Billing Address Line 2:</span>
        <input v-model="billingAddressLine2" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Billing Suburb *:</span>
        <input v-model="billingSuburb" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Billing City *:</span>
        <input v-model="billingCity" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Billing Postal Code *:</span>
        <input v-model="billingPostalCode" type="text" class="form-control">
      </div>
    </div>
    <div class="col-md-12 col-xl-6">
      <div class="input-group mb-3" v-if="!isSameAddress">
        <span class="input-group-text">Address Line 1:</span>
        <input v-model="addressLine1" type="text" class="form-control">
      </div>
      <div class="input-group mb-3" v-if="!isSameAddress">
        <span class="input-group-text">Address Line 2:</span>
        <input v-model="addressLine2" type="text" class="form-control">
      </div>
      <div class="input-group mb-3" v-if="!isSameAddress">
        <span class="input-group-text">Suburb:</span>
        <input v-model="suburb" type="text" class="form-control">
      </div>
      <div class="input-group mb-3" v-if="!isSameAddress">
        <span class="input-group-text">City:</span>
        <input v-model="city" type="text" class="form-control">
      </div>
      <div class="input-group mb-3" v-if="!isSameAddress">
        <span class="input-group-text">Postal Code:</span>
        <input v-model="postalCode" type="text" class="form-control">
      </div>
    </div>
  </div>
  <div class="row" v-if="!order">
    <div class="col-md-12 col-xl-6">
      <vue-recaptcha ref="recaptcha"
                     @verify="onVerify"
                     @expired="onExpired"
                     :sitekey="sitekey">
      </vue-recaptcha>
    </div>
    <div class="col-md-12 col-xl-6">
      <div class="input-group mb-3">
        <button type="button" class="btn btn-outline-success" @click="checkout">
          <i class="fa fa-shopping-cart"></i> Checkout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DangerAlert from "../shared/alerts/DangerAlert.vue";
import { VueRecaptcha } from 'vue-recaptcha';
import OrderPayment from "./OrderPayment.vue";

export default {
  name: 'cart-form',
  props: {
    cart: String,
    cartUpdated: Boolean,
  },
  data() {
    return {
      order: null,
      items: [],
      name: null,
      company: null,
      contact: null,
      email: null,
      addressLine1: null,
      addressLine2: null,
      suburb: null,
      city: null,
      postalCode: null,
      billingAddressLine1: null,
      billingAddressLine2: null,
      billingSuburb: null,
      billingCity: null,
      billingPostalCode: null,
      deliveryMethod: 'D',
      paymentMethod: 'CARD',
      sameDelivery: null,
      isSameAddress: false,
      spamCheck: false,
      deliveryCost: 0.00,
      discountApplied: 0.00,
      errors: [],
      sitekey: '6Le1KNcUAAAAADKWyXkfUAx-gFqgdAeqh21Kj58G',
    }
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.axios.get("/api/cart/" + this.cart + "/items").then((response) => {
        if (response.status === 200) {
          this.items = response.data
        }
      })
    },
    cartCount() {
      return this.items
          .map(({quantity}) => quantity)
          .reduce((a, b) => a + b, 0)
    },
    cartTotal() {
      let total = 0;
      this.items.forEach((item, i) => {
        total += item.quantity * item.unit_price;
      });
      let discount = 0;
      if (this.paymentMethod === 'EFT') {
        discount = total * 0.035;
        this.discountApplied = (total * 0.035).toFixed(2);
      } else {
        this.discountApplied = 0.00;
      }
      if (this.deliveryMethod === 'D') {
        this.deliveryCost = 285.00;
      } else {
        this.deliveryCost = 0.00;
      }
      return ((total - discount) + this.deliveryCost).toFixed(2);
    },
    orderTotal() {
      return this.cartTotal() + this.deliveryCost;
    },
    checkout() {
      this.validate();
      this.addressLine1 = this.billingAddressLine1;
      this.addressLine2 = this.billingAddressLine2;
      this.suburb = this.billingSuburb;
      this.city = this.billingCity;
      this.postalCode = this.billingPostalCode;

      const payLoad = {
        order: {
          name: this.name,
          telephone: this.contact,
          email: this.email,
          company: this.company,
          address_line_1: this.addressLine1,
          address_line_2: this.addressLine2,
          suburb: this.suburb,
          city: this.city,
          postal_code: this.postalCode,
          billing_address_line_1: this.billingAddressLine1,
          billing_address_line_2: this.billingAddressLine2,
          billing_suburb: this.billingSuburb,
          billing_city: this.billingCity,
          billing_postal_code: this.billingPostalCode,
          payment_method: this.paymentMethod,
          delivery_method: this.deliveryMethod,
          cart_total: this.cartTotal(),
          order_total: this.orderTotal(),
          delivery_cost: this.deliveryCost,
          cart_id: this.cart,
        }
      };
      this.axios.post('/checkout.json', payLoad).then((response) => {
        if (response.status === 200) {
          this.order = response.data;
          this.resetInputs();
          this.$emit("order-placed", this.order);
        }
      })
    },
    validate() {
      this.errors = [];
      if (!this.name) {
        this.errors.push('Name is required');
      }
      if (!this.contact) {
        this.errors.push('Contact is required');
      }
      if (!this.email) {
        this.errors.push('Email is required');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.billingAddressLine1) {
        this.errors.push('Billing Address Line 1 is required');
      }
      if (!this.billingSuburb) {
        this.errors.push('Billing Suburb is required');
      }
      if (!this.billingCity) {
        this.errors.push('Billing City is required');
      }
      if (!this.billingPostalCode) {
        this.errors.push('Postal Code is required');
      }
      if (!this.deliveryMethod) {
        this.errors.push('Delivery Method is required');
      }
      if (!this.paymentMethod) {
        this.errors.push('Payment Method is required');
      }
      if (!this.spamCheck) {
        this.errors.push('Spam Recaptcha failed');
      }
    },
    validEmail: function (email) {
      let check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return check.test(email);
    },
    onVerify: function (response) {
      if (response) {
        this.spamCheck = true;
      }
    },
    onExpired: function () {
      console.log('Expired');
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
    },
    resetInputs() {
      this.name = '';
      this.company = '';
      this.contact = '';
      this.email = '';
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.suburb = '';
      this.city = '';
      this.postalCode = '';
      this.billingAddressLine1 = '';
      this.billingAddressLine2 = '';
      this.billingSuburb = '';
      this.billingCity = '';
      this.billingPostalCode = '';
      this.deliveryMethod = 'D';
      this.paymentMethod = 'CARD';
      this.sameDelivery = null;
      this.isSameAddress = false;
      this.resetRecaptcha();
    },
  },
  watch: {
    cartUpdated() {
      if (this.cartUpdated === true) {
        this.getItems();
        this.$emit("summary-updated", true);
      }
    },
    sameDelivery() {
      this.isSameAddress = this.sameDelivery === 'same';
    },
  },
  components: {
    OrderPayment,
    DangerAlert,
    VueRecaptcha,
  },
}
</script>

