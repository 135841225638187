<template>
  <DangerAlert v-if="errors.length" v-for="(message) in errors" :message="message"/>
  <h1 v-if="!orderComplete" class="page_titles">
    <i class="fa fa-minus"></i>
      Your Cart
    <i class="fa fa-minus"></i>
  </h1>
  <h1 v-if="orderComplete" class="page_titles">
    <i class="fa fa-minus"></i>
    Process Payment
    <i class="fa fa-minus"></i>
  </h1>
  <Items v-if="!orderComplete" :cart="cart" @cart-updated="cartUpdated"/>
  <CartForm :cart="cart" :cartUpdated="updateSummary"
            @summary-updated="updateSummary = false" @order-placed="orderPlaced"/>
</template>

<script>
import DangerAlert from "../shared/alerts/DangerAlert.vue";
import SuccessAlert from "../shared/alerts/SuccessAlert.vue";
import Items from "./Items.vue";
import CartForm from "./CartForm.vue";

export default {
  name: 'cart',
  props: {
    cart: Number,
  },
  data() {
    return {
      errors: [],
      orderComplete: false,
      successMessage: 'Thank You - Your order has been placed',
      updateSummary: false,
    }
  },
  methods: {
    cartUpdated() {
      this.updateSummary = true;
    },
    orderPlaced() {
      this.orderComplete = true;
    }
  },
  components: {
    CartForm,
    DangerAlert,
    SuccessAlert,
    Items,
  },
}
</script>

