<template>
  <tr>
    <td>{{ this.item.quantity }}</td>
    <td>{{ this.title }}</td>
    <td>{{ this.shape }}</td>
    <td>{{ this.size }}</td>
    <td>{{ this.cut }}</td>
    <td>{{ this.clarity }}</td>
    <td>{{ this.colour }}</td>
    <td>{{ this.polish }}</td>
    <td>{{ this.symmetric }}</td>
    <td>R{{ this.item.unit_price }}</td>
    <td>R{{ this.total }}</td>
    <td>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button v-if="this.quantity > 1 && this.quantity !== 1" type="button" class="btn btn-sm btn-primary" @click="decrementItem">
          <i class="fa fa-chevron-down"></i>
        </button>
        <button v-if="this.item.moissanite_id" type="button" class="btn btn-sm btn-secondary" @click="incrementItem">
          <i class="fa fa-chevron-up"></i>
        </button>
        <button type="button" class="btn btn-sm btn-outline-danger" @click="removeItem">
          <i class="fa fa-trash-o"></i>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>

export default {
  name: 'item',
  props: {
    item: Object,
  },
  data() {
    return {
      title: null,
      shape: null,
      size: null,
      cut: null,
      clarity: null,
      colour: null,
      polish: null,
      symmetric: null,
      quantity: this.item.quantity,
      unit_price: this.item.unit_price,
      total: 0.00,
    }
  },
  created() {
    this.calculateTotal();
    if (this.item.diamond_id) {
      this.getDiamondInfo();
    }

    if (this.item.piece_id) {
      this.getPieceInfo();
    }

    if (this.item.lab_grown_diamond_id) {
      this.getLabGrownDiamondInfo();
    }

    if (this.item.moissanite_id) {
      this.getMoissaniteInfo();
    }
  },
  methods: {
    getDiamondInfo() {
      this.axios.get("/api/cart/" + this.item.diamond_id + "/diamond").then((response) => {
        if (response.status === 200) {
          this.title = response.data.title;
          this.shape = response.data.shape;
          this.size = response.data.size;
          this.cut = response.data.cut;
          this.clarity = response.data.clarity;
          this.colour = response.data.colour;
          this.polish = response.data.polish;
          this.symmetric = response.data.symmetric;
        }
      })
    },
    getLabGrownDiamondInfo() {
      this.axios.get("/api/cart/" + this.item.lab_grown_diamond_id + "/lab_grown_diamond").then((response) => {
        if (response.status === 200) {
          this.title = response.data.title;
          this.shape = response.data.shape;
          this.size = response.data.size;
          this.cut = response.data.cut;
          this.clarity = response.data.clarity;
          this.colour = response.data.colour;
          this.polish = response.data.polish;
          this.symmetric = response.data.symmetric;
        }
      })
    },
    getMoissaniteInfo() {
      this.axios.get("/api/cart/" + this.item.moissanite_id + "/moissanite").then((response) => {
        if (response.status === 200) {
          this.title = response.data.title;
          this.shape = response.data.shape;
          this.size = response.data.size;
          this.cut = response.data.cut;
          this.clarity = response.data.clarity;
          this.colour = response.data.colour;
          this.polish = response.data.polish;
          this.symmetric = response.data.symmetric;
        }
      })
    },
    getPieceInfo() {
      this.axios.get("/api/cart/" + this.item.piece_id + "/piece").then((response) => {
        if (response.status === 200) {
          this.title = response.data.title;
          this.size = response.data.total_diamonds;
        }
      })
    },
    decrementItem() {
      this.$emit("item-decrement", this.item.id);
      this.quantity--;
      this.calculateTotal();
    },
    incrementItem() {
      this.$emit("item-increment", this.item.id);
      this.quantity++;
      this.calculateTotal();
    },
    removeItem() {
      this.$emit("item-deleted", this.item.id);
    },
    calculateTotal() {
      this.total = (this.quantity * this.unit_price).toFixed(2);
    },
  },
}
</script>

