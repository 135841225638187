<template>
  <div class="row">
    <div class="col-6">
      <SuccessAlert :message="successMessage()"/>
    </div>
    <div class="col-6">
      <div v-if="isCardPayment" class="alert alert-success" role="alert">
        <h4 class="alert-heading">SA Diamond Source Card Payment Details</h4>
        <p>
          We will process your order and will send you a payment link for your card payment.<br>
          <br>
          Amount Due: {{ order.order_total }}
        </p>
        <hr>
        <p class="mb-0">Please contact us if you have any questions</p>
      </div>

      <div v-if="isEFTPayment" class="alert alert-success" role="alert">
        <h4 class="alert-heading">SA Diamond Source Banking Details</h4>
        <p>
          Account Name: SA Diamond Source <br>
          Current account number: 1284129799 <br>
          Bank: Nedbank <br>
          Branch: Business Banking Central, Gauteng <br>
          Branch code: 128405 <br>
          Swift code: NEDSZAJJ <br>
          Payment reference: {{ order.name }} - SAD#{{ order.id }} <br>
          <br>
          Amount Due: {{ order.order_total }}
        </p>
        <hr>
        <p class="mb-0">Please contact us if you have any questions</p>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessAlert from "../shared/alerts/SuccessAlert.vue";

export default {
  name: 'order-payment',
  props: {
    paymentMethod: String,
    order: Object,
  },
  data() {
    return {
      isEFTPayment: false,
      isCardPayment: false,
      eftMessage: 'Kindly make your EFT Payment to the details provided',
    }
  },
  methods: {
    successMessage() {
      return 'Thank you ' +  this.order.name + '. Your order has been placed';
    },
    whichPaymentMethod() {
      if (this.order.payment_method === 'EFT') {
        this.isCardPayment = false;
        this.isEFTPayment = true;
      } else {
        this.isCardPayment = true;
        this.isEFTPayment = false;
      }
    },
  },
  mounted() {
    this.whichPaymentMethod();
  },
  components: {
    SuccessAlert,
  }
}
</script>

