<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Qty</th>
        <th>Title</th>
        <th>Shape</th>
        <th>Size</th>
        <th>Cut</th>
        <th>Clarity</th>
        <th>Colour</th>
        <th>Polish</th>
        <th>Symmetric</th>
        <th>Unit Price</th>
        <th>Total</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <Item v-if="items.length" v-for="(item, index) in items"
            v-bind:item="item"
            v-bind:index="index"
            v-bind:key="item.id"
            @item-deleted="removeItem"
            @item-increment="incrementItem"
            @item-decrement="decrementItem"
      />
    <tr>
      <td>{{ cartCount() }}</td>
      <td><b>Total Items</b></td>
      <td colspan="7"></td>
      <td><b>Total</b></td>
      <td><b>R{{ cartTotal() }}</b></td>
      <th>
        <button type="button" class="btn btn-sm btn-outline-danger" @click="emptyCart">
          <i class="fa fa-trash-o"></i> Empty
        </button>
      </th>
    </tr>
    </tbody>
  </table>
</template>

<script>

import Item from "./Item.vue";

export default {
  name: 'items',
  props: {
    cart: String,
  },
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.axios.get("/api/cart/" + this.cart + "/items").then((response) => {
        if (response.status === 200) {
          this.items = response.data
        }
      })
    },
    incrementItem(id) {
      this.axios.get("/api/cart/items/" + id + "/increment").then((response) => {
        if (response.status === 200) {
          this.getItems();
          this.$emit("cart-updated", this.cart);
        }
      })
    },
    decrementItem(id) {
      this.axios.get("/api/cart/items/" + id + "/decrement").then((response) => {
        if (response.status === 200) {
          this.getItems();
          this.$emit("cart-updated", this.cart);
        }
      })
    },
    removeItem(id) {
      this.axios.get("/api/cart/items/" + id + "/remove").then((response) => {
        if (response.status === 200) {
          this.getItems();
          this.$emit("cart-updated", this.cart);
        }
      })
    },
    emptyCart() {
      this.axios.get("/api/cart/" + this.cart + "/empty").then((response) => {
        if (response.status === 200) {
          this.getItems();
          this.$emit("cart-updated", this.cart);
        }
      })
    },
    cartCount() {
      return this.items
          .map(({quantity}) => quantity)
          .reduce((a, b) => a + b, 0)
    },
    cartTotal(){
      let total = 0;
      this.items.forEach((item, i) => {
        total += item.quantity * item.unit_price;
      });
      return total.toFixed(2);
    },
  },
  components: {
    Item,
  },
}
</script>

