<template>
  <div class="modal-header">
    <h5 class="modal-title">{{ modalTitle }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <DangerAlert v-if="errors.length" v-for="(message) in errors" :message="message"/>
      <SuccessAlert v-if="success" :message="successMessage"/>
      <div class="input-group mb-3">
        <span class="input-group-text">Name*:</span>
        <input v-model="name" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Contact Number*:</span>
        <input v-model="contact" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Email*:</span>
        <input v-model="email" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Budget:</span>
        <input v-model="budget" type="text" class="form-control">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Message:</span>
        <input v-model="message" type="text" class="form-control">
      </div>
      <vue-recaptcha ref="recaptcha"
                     @verify="onVerify"
                     @expired="onExpired"
                     :sitekey="sitekey">
      </vue-recaptcha>
      <div class="input-group mb-3">
        <button @click="submitContact" type="button" class="button_blue hvr-pop">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import DangerAlert from "../shared/alerts/DangerAlert.vue";
import SuccessAlert from "../shared/alerts/SuccessAlert.vue";
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'lab-grown-diamond-contact-form',
  props: {
    item: Number,
  },
  data() {
    return {
      modalTitle: 'Enquiry About This Lab Grown Diamond',
      name: null,
      email: null,
      contact: null,
      interest: null,
      budget: null,
      message: null,
      spamCheck: false,
      errors: [],
      success: false,
      successMessage: 'Thank you. We will be in contact',
      sitekey: '6Le1KNcUAAAAADKWyXkfUAx-gFqgdAeqh21Kj58G',
    }
  },
  methods: {
    validate() {
      this.errors = [];
      if (!this.name) {
        this.errors.push('Name is required');
      }
      if (!this.contact) {
        this.errors.push('Contact is required');
      }
      if (!this.email) {
        this.errors.push('Email is required');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }
      if (!this.spamCheck) {
        this.errors.push('Spam Recaptcha failed');
      }
    },
    validEmail: function (email) {
      let check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return check.test(email);
    },
    onVerify: function (response) {
      if (response) {
        this.spamCheck = true;
      }
    },
    onExpired: function () {
      console.log('Expired');
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
    },
    submitContact() {
      this.validate();

      if (this.errors.length === 0) {
        const payLoad = {
          name: this.name,
          telephone: this.contact,
          email: this.email,
          interest: this.interest,
          budget: this.budget,
          requirements: this.message,
          lab_grown_diamond_id: this.item,

          page_source: 0,
        };
        this.axios.post('/leads', payLoad).then((response) => {
          if (response.status === 200) {
            this.success = true;
            this.resetInputs();
          }
        })
      }
    },
    resetInputs() {
      this.name = '';
      this.contact = '';
      this.email = '';
      this.interest = '';
      this.budget = '';
      this.message = '';
      this.resetRecaptcha();
    },
  },
  components: {
    DangerAlert,
    SuccessAlert,
    VueRecaptcha,
  },
}
</script>

