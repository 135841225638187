// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// Contact Form Vue App
// import { createApp } from "vue";
import { createApp } from 'vue/dist/vue.esm-bundler';
import VueAxios from "vue-axios";
import axios from "axios";
import ContactForm from "../../views/front/vue_components/contact_form/ContactForm.vue";
import EarringContactForm from "../../views/front/vue_components/earring_form/EarringContactForm.vue";
import PendantContactForm from "../../views/front/vue_components/pendant_form/PendantContactForm.vue";
import PieceContactForm from "../../views/front/vue_components/piece_form/PieceContactForm.vue";
import RingContactForm from "../../views/front/vue_components/ring_form/RingContactForm.vue";
import MoissaniteContactForm from "../../views/front/vue_components/moissanite_form/MoissaniteContactForm.vue";
import DiamondContactForm from "../../views/front/vue_components/diamond_form/DiamondContactForm.vue";
import LabGrownDiamondContactForm from "../../views/front/vue_components/lab_grown_diamond_form/LabGrownDiamondContactForm.vue";
import Cart from "../../views/front/vue_components/cart/Cart.vue";
import TestApp from "../components/TestApp.vue"

// createApp(TestApp).mount('#test-app')
// createApp(ContactForm).use(VueAxios, axios).mount('#contact-form-app')
// createApp(EarringContactForm).use(VueAxios, axios).mount('#earring-enquiry-form')

const contactApp = createApp({
    data() {
        return {}
    }
})
contactApp.use(VueAxios, axios);
contactApp.component('ContactForm', ContactForm)
contactApp.mount('#contact_form_app');

const earringFormApp = createApp({
    data() {
        return {}
    }
})
earringFormApp.use(VueAxios, axios);
earringFormApp.component('EarringContactForm', EarringContactForm)
earringFormApp.mount('#earring_enquiry_form');

const pendantFormApp = createApp({
    data() {
        return {}
    }
})
pendantFormApp.use(VueAxios, axios);
pendantFormApp.component('PendantContactForm', PendantContactForm)
pendantFormApp.mount('#pendant_enquiry_form');

const pieceFormApp = createApp({
    data() {
        return {}
    }
})
pieceFormApp.use(VueAxios, axios);
pieceFormApp.component('PieceContactForm', PieceContactForm)
pieceFormApp.mount('#piece_enquiry_form');

const ringFormApp = createApp({
    data() {
        return {}
    }
})
ringFormApp.use(VueAxios, axios);
ringFormApp.component('RingContactForm', RingContactForm)
ringFormApp.mount('#ring_enquiry_form');

const moissaniteFormApp = createApp({
    data() {
        return {}
    }
})
moissaniteFormApp.use(VueAxios, axios);
moissaniteFormApp.component('MoissaniteContactForm', MoissaniteContactForm)
moissaniteFormApp.mount('#moissanite_enquiry_form');

const diamondFormApp = createApp({
    data() {
        return {}
    }
})
diamondFormApp.use(VueAxios, axios);
diamondFormApp.component('DiamondContactForm', DiamondContactForm)
diamondFormApp.mount('#diamond_enquiry_form');

const labGrownDiamondFormApp = createApp({
    data() {
        return {}
    }
})
labGrownDiamondFormApp.use(VueAxios, axios);
labGrownDiamondFormApp.component('LabGrownDiamondContactForm', LabGrownDiamondContactForm)
labGrownDiamondFormApp.mount('#lab_enquiry_form');

// Shop & Cart Vue Components
const cartApp = createApp({
    data() {
        return {}
    }
})
cartApp.use(VueAxios, axios);
cartApp.component('Cart', Cart)
cartApp.mount('#cart_app');